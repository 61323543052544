import styles from "./Home.module.scss";

import leaderboard_image from "../assets/leaderboard.png";
import profile_info from "../assets/profile-info.png";
import profile_badges from "../assets/profile-badges.png";
import profile_games from "../assets/profile-games.png";
import mino_profile from "../assets/mino-profile.png";
import steamsets_group from "../assets/steamsets-group.png";
import labrador_badges from "../assets/labrador_badges.png";
import logo from "../assets/logo.png";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { SteamSets } from "@steamsets/client-ts";

const steamSets = new SteamSets({
  token: "api_prod_2UiEZH2gG7S2MoJycvcYejACHAKvr5N2LZ6rvtLxPtWyNR",
});

export default function HomePage() {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <section className={styles.top}>
        <div className={styles.cta}>
          <div className={styles.content}>
            <h1>
              The Steam leaderboards for the games you love, all in one place.
            </h1>

            <h4>Ascend the leaderboards, level up and more!</h4>
          </div>

          <div className={styles.buttons}>
            <label htmlFor="email-input">@</label>
            <input
              id="email-input"
              type="email"
              placeholder="your@email.com"
              ref={inputRef}
              className={styles.input}
            />
            <button
              className="Button Button--Gradient"
              type="button"
              id="cta-btn"
              onClick={async (e) => {
                e.preventDefault();
                const value = inputRef.current?.value;
                if (!value || !value.includes("@")) {
                  alert("Please enter an email address");
                  return;
                }

                try {
                  await steamSets.settings.accountV1SettingsEmailSubscribe({
                    v1EmailSubscriptionRequestBody: {
                      email: value,
                      subscribed: true,
                    },
                  });

                  alert("You've been added to the waiting list.");
                } catch (e) {
                  console.error(e);
                  alert("An error occurred, please try again later.");
                }
              }}
            >
              Join the waiting list
            </button>
          </div>

          <img src={logo} className={styles.logo} alt="SteamSets" />
        </div>
        <div className={styles.scroll_down}>
          <span />
          <span />
          <span />
        </div>

        <div className={styles.stats}>
          <div className={styles.item}>
            <h1 className={styles.number}>24/7</h1>
            <h5 className={styles.info}>Live Support</h5>
          </div>

          <div className={styles.item}>
            <h1 className={styles.number}>20+</h1>
            <h5 className={styles.info}>Leaderboards</h5>
          </div>

          <div className={styles.item}>
            <h1 className={styles.number}>???</h1>
            <h5 className={styles.info}>Profiles tracked</h5>
          </div>

          <div className={styles.item}>
            <h1 className={styles.number}>???</h1>
            <h5 className={styles.info}>Games tracked</h5>
          </div>
        </div>

        <div className={styles.image}>
          <img
            src={leaderboard_image}
            className={styles.leaderboard}
            alt="Leaderboard"
          />
        </div>
      </section>

      <section className={styles.profile}>
        <h1>View your profile in a whole new way</h1>

        <h4>See your stats in a new light, compare with friends, and more.</h4>

        <div className={styles.images}>
          <img
            src={profile_info}
            className={styles.info_image}
            alt="Leaderboard"
          />
          <img
            src={profile_badges}
            className={styles.info_image}
            alt="Leaderboard"
          />
        </div>
      </section>

      <section className={styles.search_engine}>
        <h1>Powerful search engine</h1>
        <h4>Looking for something specific? We've got you covered.</h4>

        <div className={styles.grid}>
          <div className={styles.item}>
            <div className={styles.info}>
              <h5>Profiles</h5>
              <p>
                Search for a Steam profile and see their stats, games, and more
                in a detailed view. Ps. If we don't have a profile yet, we will
                add it to the queue.
              </p>
            </div>
            <img src={mino_profile} alt="Profile" />
          </div>

          <div className={styles.item}>
            <div className={styles.info}>
              <h5>Games</h5>
              <p>Looking for something new to play?</p>
            </div>
            <img src={profile_games} alt="Games" />
          </div>

          <div className={styles.item}>
            <div className={styles.info}>
              <h5>Groups</h5>
              <p>
                Interested in Steam groups? Find the one you're looking for.
              </p>
            </div>
            <img src={steamsets_group} alt="Steamgroups" />
          </div>

          <div className={styles.item}>
            <div className={styles.info}>
              <h5>Badges</h5>
              <p>Find the badges you're looking for in a snap.</p>
            </div>
            <img src={labrador_badges} alt="Badges" />
          </div>
        </div>
      </section>

      <section className={styles.support_us}>
        <h1>Do you like what we're doing?</h1>

        <h4>
          Help us grow and support us on our journey to make SteamSets the best
          platform for Steam enthusiasts.
        </h4>

        <Link
          to="https://steamsets.link/donate?ref=landing-page"
          target="_blank"
          className="Button Button--Gradient text-center"
        >
          Support us
        </Link>
      </section>
    </>
  );
}
